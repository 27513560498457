import React from 'react'
import "./edu.css"
import HEAD from "./header/head"

const edu = () => {
  return (
    <div id= "Education">
        <HEAD/>
    </div>
  )
}

export default edu
