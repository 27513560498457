import React from 'react'

import TIME from "./TIMELINE/time"
import PROJECT from "./PROJECTS/projects"


const work = () => {
  return (
    <div>
        <TIME/>
        <PROJECT/>
    </div>
  )
}

export default work
