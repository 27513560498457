import React from 'react'
import "./hobbies.css"

import HEAD from "./header/head"
import HS from "./hobbySection/hs"
import COMICS from "./COMICS/comics"


const hobbies = () => {
  return (
    <div>
      <HEAD/>
      <HS/>
      <COMICS/>
    </div>
  )
}

export default hobbies
