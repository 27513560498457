import React from 'react'
import "./head.css"

const head = () => {

    
  return (
    <div>
      <div id="root">

        
      </div>
    </div>
  )
}

export default head
